import {Utils, PropTypes, FlashTimer, MyassaysComponent} from 'myassays-global';
import styles from './styles.css?raw';

const { repeat } = Utils;

export default class MyassaysLayoutViewer extends MyassaysComponent {
    static get propTypes() {
        return {
            id: PropTypes.string,
            class: PropTypes.string,
            style: PropTypes.string,
            onclick: PropTypes.string,
            dataConfig: PropTypes.json.default({}).observed,
            flashEnabled: PropTypes.bool.default(true).observed,
            flashWellPositions: PropTypes.string.regExp(/^\d*( *, *\d*)*$/).default('').observed,
            flashColour: PropTypes.string.default('black'),
            flashBackgroundColour: PropTypes.string.default('white'),
        }
    }
    constructor(options) {
        super();

        if (options) {
            this.options = options;
            this.id = options.id;
        }

        this.shadow = this.attachShadow({mode: "open"});
        this._hasBeenRendered = false;

        if (options) {
            const parentElem = options.parent;
            if (parentElem) {
                parentElem.appendChild(this);
            }
        }
    }

    static get observedAttributes() {
        return PropTypes.getObserved(this.propTypes);
    }

    static template = data => `
    <style>

    ${styles}

    ${data.sampleTypes.map(item => `.cell[data-sample-type="${item.Id}"] {background-color: ${item.Colour.toLowerCase()}}`).join(' ')}
    
    .flash-on .flashing {
        color: ${data.flashColour} !important;
        background-color: ${data.flashBackgroundColour} !important;
    }

    </style>

    <div class="layout">
        <table part="table" class="${FlashTimer.state ? 'flash-on' : ''}">
            <tbody>
                <tr class="top-row">
                    <td class="heading">&nbsp;</td>
                    ${repeat(data.width, col => `<td part="heading" class="heading">${col + 1}</td>`)}
                </tr>
                ${repeat(data.height, row => `<tr><td part="heading" class="heading">${data.toRowCode(row + 1)}</td>${repeat(data.width, col => {
                    const wellIndex = row*data.totalColumns + col;
                    const flashingClass = data.flashEnabled && data.flashWellPositions.includes(wellIndex + 1) ? `flashing` : '';
            
                    const sampleType = data.default.shift();
                    let number = data.default.shift();
                    if (sampleType === '1' || number === '0') {
                        number = '&nbsp;';
                    }
                    return `<td data-sample-type="${sampleType}" part="cell" class="cell ${flashingClass}">${number}</td>`;
                })}</tr>`)}
            </tbody>
        </table>
    </div>
`
    onFlashStateChange = state => {
        const table = this.shadow.querySelector('table');
        if (table) {
            table.classList.toggle('flash-on', state);
        }
    }

    render(data) {
        return this.constructor.template(data);
    }

    configure() {
        let config;
        if (!this.options) {
            config = this._props.dataConfig;
        } else {
            config = this.options.config;
        }
        const width = this._width = parseInt(config.Width);
        const height = this._height = parseInt(config.Height);

        const data = {
            width,
            height,
            default: config.Default.split(','),
            sampleTypes: config.SampleTypes,
            flashEnabled: this._props.flashEnabled,
            flashWellPositions: this._props.flashWellPositions ? this._props.flashWellPositions.split(/ *, */).map(item => Number(item)) : [],
            flashColour: this._props.flashColour,
            flashBackgroundColour: this._props.flashBackgroundColour,
            totalColumns: config.Width,
            toRowCode: row => row > 26 ? String.fromCharCode(64 + Math.floor(row / 26)) + String.fromCharCode(64 + row % 26) : String.fromCharCode(64 + row)
        }
        this.shadow.innerHTML = this.render(data);
        this.resize();
    }

    resize = () => {
        const tableElement = this.shadow.querySelector('table');
        if (!this._isSized) {
            const pixelWidth = tableElement.getBoundingClientRect().width;
            const borderSpacing = parseInt(getComputedStyle(tableElement).borderSpacing.split(/ +/)[0]);
            this._cellSize = (pixelWidth - borderSpacing * this._width) / (this._width + 1);
        }

        if (this._cellSize > 0) {
            tableElement.style.setProperty('--cell_size', `${this._cellSize}px`);
            this._isSized = true;
        }
    }

    connectedCallback() {
        this._props = PropTypes.attributesToProps(this);
        this.configure();
        this.resizeObserver = new ResizeObserver(this.resize);
        this.resizeObserver.observe(this);
        this._hasBeenRendered = true;
        FlashTimer.subscribe(this.onFlashStateChange);
    }

    disconnectedCallback() {
        FlashTimer.unSubscribe(this.onFlashStateChange);
        this.resizeObserver.disconnect();
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (this._hasBeenRendered) {
            this._props = {...this._props, ...PropTypes.attributesToProps(this, attrName)};
            this.configure();
        }
    }
}
